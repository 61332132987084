import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { bridgeFinanceFilters } from "../../bridgeFinance/constants/bridgeFinanceFilters";
import { overviewFilters } from "../overviewFilters";
import { useLoanParams } from "../hooks/useLoanParams";
import { format } from "date-fns";
import CustomDate from "../../../common/components/CustomDate";
import dayjs from "dayjs";
const d = new Date();
export const LoanPortfolioMetrics = ({
  overview,
  loanStatus,
  loanTypes,
  ranges,
}) => {
  const { getDateRange, fetchLoanMetrics, dispatch } = useLoanParams();
  const [filter, setFilter] = useState(overviewFilters.LAST7DAYS);
  const [loanStatusFilter, setLoanStatusFilter] = useState("");
  const [loanTypeFilter, setLoanTypeFilter] = useState("");
  const [customFilter, setCustomFilter] = useState({
    open: false,
    startDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
    endDate: format(d.setDate(d.getDate()), "yyyy-MM-dd"),
  });

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({});

  const handleLoanStatusFilterChange = (event) => {
    setLoanStatusFilter(event.target.value);
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);

    const data = {
      ...range,
      type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
      status: event.target.value === "" ? "ALL" : event.target.value,
    };
    setSearchParams(data);
    dispatch(fetchLoanMetrics(data));
  };

  const handleLoanTypeFilterChange = (event) => {
    setLoanTypeFilter(event.target.value);
    const range = filter === "CUSTOM" ? customFilter : getDateRange(filter);

    const data = {
      ...range,
      type: event.target.value === "" ? "ALL" : event.target.value,
      status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
    };
    setSearchParams(data);
    dispatch(fetchLoanMetrics(data));
  };

  const handleStatisticsFilterChange = (event) => {
    setFilter(event.target.value);
    const range = getDateRange(event.target.value);
    if (event.target.value !== "CUSTOM") {
      setCustomFilter({ ...customFilter, open: false });

      const data = {
        ...range,
        type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
        status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
      };

      setSearchParams(data);

      dispatch(fetchLoanMetrics(data));
    } else {
      setCustomFilter({
        startDate: range.startDate,
        endDate: range.endDate,
        open: true,
      });
    }
  };
  const handleCustomFilter = (name, value) => {
    setCustomFilter({
      ...customFilter,
      [name]: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };
  const applyCustomFilter = () => {
    setCustomFilter({ ...customFilter, open: false });

    const data = {
      startDate: customFilter.startDate,
      endDate: customFilter.endDate,
      type: loanTypeFilter === "" ? "ALL" : loanTypeFilter,
      status: loanStatusFilter === "" ? "ALL" : loanStatusFilter,
    };
    setSearchParams(data);

    dispatch(fetchLoanMetrics(data));
  };

  const handleDisburseClick = () => {
    const { type, status, ...others } = searchParams;
    const param = {
      ...others,
    };

    if (type) param["loanTypeFilter"] = type;
    if (status && status !== "ALL") param["status"] = status;

    navigate({
      pathname: "/loans",
      search: createSearchParams(param).toString(),
    });
  };
  // const handleDueClick = () => {
  //   navigate("/bridge-finance", {
  //     state: { filter: bridgeFinanceFilters.ACTIVE },
  //   });
  // };
  // const handleCollectionsClick = () => {
  //   navigate("/bridge-finance", {
  //     state: { filter: bridgeFinanceFilters.COMPLETED },
  //   });
  // };
  // const handleOutstandingClick = () => {
  //   navigate("/bridge-finance", {
  //     state: { filter: bridgeFinanceFilters.DEFAULTING },
  //   });
  // };
  // const handleInterestClick = () => {
  //   navigate("/bridge-finance", {
  //     state: { filter: bridgeFinanceFilters.PAY_INTEREST },
  //   });
  // };
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "₦");
  };
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        pt: 2,
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <FormControl sx={{ mr: 2.5, mt: 1 }}>
          <Select
            value={loanTypeFilter}
            size="small"
            onChange={handleLoanTypeFilterChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={styles.dropdown}
          >
            {loanTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ mr: 2.5, mt: 1 }}>
          <Select
            value={loanStatusFilter}
            size="small"
            onChange={handleLoanStatusFilterChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={styles.dropdown}
          >
            {loanStatus.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ mr: 3.5, mt: 1, position: "relative" }}>
          <Select
            value={filter}
            size="small"
            onChange={handleStatisticsFilterChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={styles.dropdown}
          >
            {ranges.map((range) => (
              <MenuItem
                onClick={() =>
                  range.value === "CUSTOM"
                    ? setCustomFilter({ ...customFilter, open: true })
                    : null
                }
                key={range.value}
                value={range.value}
              >
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {customFilter.open ? (
          <CustomDate
            handleCustomFilter={handleCustomFilter}
            customFilter={customFilter}
            close={() => setCustomFilter({ ...customFilter, open: false })}
            applyCustomFilter={applyCustomFilter}
          />
        ) : (
          <></>
        )}
      </Box>
      {overview.loadingLoanMetrics ? (
        <Box
          sx={{
            width: "100%",
            height: 180,
            // backgroundColor: "#fff",
            borderRadius: 2,
            mt: 1,
          }}
        >
          {/* <CircularProgress sx={{ alignItems: "center" }} /> */}
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
            <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 16 }}>
              Loan Portfolio Metrics
            </Typography>
          </Box>
          <Box sx={{ px: 2.5 }}>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: "10px",
                  gap: "20px",
                }}
              >
                {[1, 2, 3, 4, 5].map((_n, n) => (
                  <Skeleton
                    variant="rectangular"
                    key={n}
                    width={"20%"}
                    height={100}
                    style={{ borderRadius: "10px" }}
                  />
                ))}
              </Box>
            </Stack>
          </Box>
        </Box>
      ) : (
        <Box
          item
          sx={{
            width: "100%",
            height: 180,
            // backgroundColor: "#fff",
            borderRadius: 2,
            mt: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
            <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 16 }}>
              Loan Portfolio Metrics
            </Typography>
          </Box>

          <Box sx={{ px: 2.5 }}>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // px: 2,
                }}
              >
                <Box
                  onClick={handleDisburseClick}
                  sx={{
                    ...styles.overview.metrics.box,
                    backgroundImage: `linear-gradient(to right, #EB4885, #E9585D)`,
                    cursor: "pointer",
                  }}
                >
                  <Stack sx={{ pl: 1 }}>
                    <Typography sx={styles.overview.customers.info2}>
                      Total Disbursed
                    </Typography>
                    <Typography sx={styles.overview.customers.number2}>
                      {toPrice(overview?.loanMetrics?.totalDisbursed)}
                    </Typography>
                  </Stack>

                  {/* <Tooltip
                    title="Total Disbursed"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip2} />
                  </Tooltip> */}
                </Box>
                <Box
                  onClick={handleDisburseClick}
                  sx={{
                    ...styles.overview.metrics.box,
                    backgroundImage: `linear-gradient(to right, #F8B0B4, #FFAF4E)`,
                    cursor: "pointer",
                  }}
                >
                  <Stack sx={{ pl: 1 }}>
                    <Typography sx={styles.overview.customers.info2}>
                      Total Due
                    </Typography>
                    <Typography sx={styles.overview.customers.number2}>
                      {toPrice(overview?.loanMetrics?.totalDue)}
                    </Typography>
                  </Stack>

                  {/* <Tooltip
                    title="Total Due"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip2} />
                  </Tooltip> */}
                </Box>
                <Box
                  onClick={handleDisburseClick}
                  sx={{
                    ...styles.overview.metrics.box,
                    backgroundImage: `linear-gradient(to right, #66CFF4, #759FDE)`,
                    cursor: "pointer",
                  }}
                >
                  <Stack sx={{ pl: 1 }}>
                    <Typography sx={styles.overview.customers.info2}>
                      Total Collected
                    </Typography>
                    <Typography sx={styles.overview.customers.number2}>
                      {toPrice(overview?.loanMetrics?.totalCollected)}
                    </Typography>
                  </Stack>

                  {/* <Tooltip
                    title=" Total Collected"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip2} />
                  </Tooltip> */}
                </Box>
                <Box
                  onClick={handleDisburseClick}
                  sx={{
                    ...styles.overview.metrics.box,
                    backgroundImage: `linear-gradient(to right, #BB8AFF, #CB91C3)`,
                    cursor: "pointer",
                  }}
                >
                  <Stack sx={{ pl: 1 }}>
                    <Typography sx={styles.overview.customers.info2}>
                      Total Outstanding
                    </Typography>
                    <Typography sx={styles.overview.customers.number2}>
                      {toPrice(overview?.loanMetrics?.totalOutstanding)}
                    </Typography>
                  </Stack>

                  {/* <Tooltip
                    title=" Total Outstanding"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip2} />
                  </Tooltip> */}
                </Box>
                <Box
                  onClick={handleDisburseClick}
                  sx={{
                    ...styles.overview.metrics.box,
                    backgroundImage: `linear-gradient(to right, #B3F066, #7AECD1)`,
                    cursor: "pointer",
                  }}
                >
                  <Stack sx={{ pl: 1 }}>
                    <Typography sx={styles.overview.customers.info2}>
                      Total Lost
                    </Typography>
                    <Typography sx={styles.overview.customers.number2}>
                      {toPrice(
                        overview?.loanMetrics?.totalLost
                          ? overview?.loanMetrics?.totalLost
                          : 0
                      )}
                    </Typography>
                  </Stack>

                  {/* <Tooltip
                    title=" Total Lost"
                    placement="top"
                    sx={{ alignSelf: "normal" }}
                  >
                    <InfoOutlined sx={styles.overview.statistics.tooltip2} />
                  </Tooltip> */}
                </Box>
                {/* <Box
              onClick={handleDisburseClick}
              sx={{
                ...styles.overview.metrics.box,
                backgroundImage: `linear-gradient(to right, #003655, #51AFA7)`,
                cursor: "pointer",
              }}
            >
              <Stack sx={{ pl: 1 }}>
                <Typography sx={styles.overview.customers.info2}>
                  Disbursed Loans
                </Typography>
                <Typography sx={styles.overview.customers.number2}>
                  {loanMetrics?.totalAmountDisbursed.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </Typography>
              </Stack>

              <Tooltip
                title="Total Loans Disbursed"
                placement="top"
                sx={{ alignSelf: "normal" }}
              >
                <InfoOutlined sx={styles.overview.statistics.tooltip2} />
              </Tooltip>
            </Box>
            <Box
              onClick={handleDueClick}
              sx={{
                ...styles.overview.metrics.box,
                backgroundImage: `linear-gradient(to right, #F8979D, #FFAF4D)`,
                cursor: "pointer",
              }}
            >
              <Stack sx={{ pl: 1 }}>
                <Typography sx={styles.overview.customers.info2}>
                  Total Due
                </Typography>
                <Typography sx={styles.overview.customers.number2}>
                  {loanMetrics?.totalAmountToBeRepayed.toLocaleString(
                    "en-NG",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )}
                </Typography>
              </Stack>

              <Tooltip
                title="Total Amount to be repaid"
                placement="top"
                sx={{ alignSelf: "normal" }}
              >
                <InfoOutlined sx={styles.overview.statistics.tooltip2} />
              </Tooltip>
            </Box>
            <Box
              onClick={handleCollectionsClick}
              sx={{
                ...styles.overview.metrics.box,
                backgroundImage: `linear-gradient(to right, #1DBCF4, #5F92DE)`,
                cursor: "pointer",
              }}
            >
              <Stack sx={{ pl: 1 }}>
                <Typography sx={styles.overview.customers.info2}>
                  Total Collections
                </Typography>
                <Typography sx={styles.overview.customers.number2}>
                  {loanMetrics?.totalAmountCollected.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                </Typography>
              </Stack>

              <Tooltip
                title="Total Amount Collected"
                placement="top"
                sx={{ alignSelf: "normal" }}
              >
                <InfoOutlined sx={styles.overview.statistics.tooltip2} />
              </Tooltip>
            </Box>
            <Box
              onClick={handleOutstandingClick}
              sx={{
                ...styles.overview.metrics.box,

                backgroundImage: `linear-gradient(to right, #FF73AC, #E9585D)`,
                cursor: "pointer",
              }}
            >
              <Stack sx={{ pl: 1 }}>
                <Typography sx={styles.overview.customers.info2}>
                  Total Outstanding
                </Typography>
                <Typography sx={styles.overview.customers.number2}>
                  {loanMetrics?.totalAmountPendingCollection.toLocaleString(
                    "en-NG",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )}
                </Typography>
              </Stack>

              <Tooltip
                title="Total Amount to be Collected"
                placement="top"
                sx={{ alignSelf: "normal" }}
              >
                <InfoOutlined sx={styles.overview.statistics.tooltip2} />
              </Tooltip>
            </Box>
            <Box
              onClick={handleInterestClick}
              sx={{
                ...styles.overview.metrics.box,
                backgroundImage: `linear-gradient(to right, #AC70FF, #CB68BD)`,
                cursor: "pointer",
              }}
            >
              <Stack sx={{ pl: 1 }}>
                <Typography sx={styles.overview.customers.info2}>
                  BF Interest Received
                </Typography>
                <Typography sx={styles.overview.customers.number2}>
                  {loanMetrics?.totalBridgeFinanceInterestCollected.toLocaleString(
                    "en-NG",
                    {
                      style: "currency",
                      currency: "NGN",
                    }
                  )}
                </Typography>
              </Stack>

              <Tooltip
                title="Bridge Finance Loans Interest Collected"
                placement="top"
                sx={{ alignSelf: "normal" }}
              >
                <InfoOutlined sx={styles.overview.statistics.tooltip2} />
              </Tooltip>
            </Box> */}
              </Box>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};
