export const businessInfoStage = {
  DETAILS: "DETAILS",
  PROFILE: "PROFILE",
  OPERATIONS: "OPERATIONS",
  OWNER_INFO: "OWNER_INFO",
  GUARANTORS: "GUARANTORS",
  COLLATERAL: "COLLATERAL",
  DOCUMENTS: "DOCUMENTS",
  BANK_STATEMENT: "BANK_STATEMENT",
};
