/* eslint-disable react-hooks/exhaustive-deps */
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { styles } from "../../../common/css/styles";
import { useDispatch, useSelector } from "react-redux";
import { roles } from "../../../common/constants/roles";
import { InfoDialog } from "../../../common/components/InfoDialog";
import { feedback } from "../../../common/constants/feedback";
import { clearFeedback } from "../usersSlice";

export const UserFormFields = ({
  buttonText,
  formObject,
  onSubmit,
  handleComplete,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = formObject;

  const [openDialog, setOpenDialog] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const user = users.user;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleCloseDialog = () => {
    dispatch(clearFeedback());
    setOpenDialog(false);
  };

  // const handleImageChange = (event) => {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setSelectedImage(e.target.result);
  //     };

  //     reader.readAsDataURL(selectedFile);
  //   }
  // };

  useEffect(() => {
    if (!users.loadingUser && buttonText === "Update") {
      setValue(
        "firstName",
        `${user?.firstName.charAt(0).toUpperCase()}${user?.firstName
          .substring(1)
          .toLowerCase()}`
      );
      setValue(
        "lastName",
        `${user?.lastName.charAt(0).toUpperCase()}${user?.lastName
          .substring(1)
          .toLowerCase()}`
      );
      setValue("email", user?.email);
      setValue("gender", user?.gender);
      setValue("phoneNumber", user?.phoneNumber);
      setValue("role", user?.roles[0].name);
    }
  }, [users.loadingUser]);

  useEffect(() => {
    if (users.feedback === feedback.CREATED) {
      setOpenDialog(true);
      setIsError(false);
      setInfoText("User added successfully");
      // handleComplete();
    } else if (users.feedback === feedback.CREATE_FAILED) {
      setOpenDialog(true);
      setIsError(true);
      setInfoText("Failed to add user");
    } else if (users.feedback === feedback.UPDATED) {
      setOpenDialog(true);
      setIsError(false);
      setInfoText("User updated successfully");
      // handleComplete();
    } else if (users.feedback === feedback.UPDATE_FAILED) {
      setOpenDialog(true);
      setIsError(true);
      setInfoText("User update failed");
    }
  }, [users.feedback]);

  useEffect(() => {
    let timeoutId;
    if (
      openDialog &&
      (users.feedback === feedback.CREATED ||
        users.feedback === feedback.UPDATED)
    ) {
      timeoutId = setTimeout(() => {
        handleCloseDialog();
        handleComplete();
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [openDialog]);

  return (
    <>
      {users.loadingUser ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
          py={2}
          px={4}
          direction="column"
          sx={{ width: "100%" }}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item>
            <Typography sx={{ fontSize: 20 }}>Admin</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ color: "#94958D", fontSize: 12 }}>
              Personal Information
            </Typography>
          </Grid>
          <Grid item sx={{ width: 200 }}>
            <Controller
              name="profileImage"
              control={control}
              render={({ field }) => (
                <label htmlFor="imageInput">
                  <Avatar
                    sx={{ bgcolor: "#ACACA980", width: 150, height: 150 }}
                    variant="square"
                    src={selectedImage}
                  />
                  {/* <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  onChange={(e) => {
                    handleImageChange(e);
                    field.onChange(e.target.files);
                  }}
                  style={{ display: "none", width: 100 }}
                /> */}
                </label>
              )}
            />
          </Grid>
          {/* <Grid item>
          <Button
            variant="contained"
            component="span"
            onClick={() => {
              const input = document.getElementById("imageInput");
              input.click();
            }}
            sx={{
              backgroundColor: "#E3E3E7",
              textTransform: "capitalize",
              width: 150,
              boxShadow: "none",
              "&:hover": {
                // backgroundColor: "#f6adad",
                boxShadow: "none",
                color: "#fff",
              },
            }}
          >
            {selectedImage ? "Change Image" : "Upload Image"}
          </Button>
        </Grid> */}
          {/* <Grid item>
            <Avatar
              sx={{ bgcolor: "#ACACA980", width: 150, height: 150 }}
              variant="square"
            />
          </Grid> */}
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                First Name
              </Typography>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="e.g. John"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    sx={styles.textField}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Last Name
              </Typography>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="e.g. Doe"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    sx={styles.textField}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Email Address
              </Typography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="e.g. johndoe@gmail.com"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    sx={styles.textField}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Phone Number
              </Typography>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    variant="outlined"
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="e.g. 12345678901"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                    sx={styles.textField}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: buttonText === "Update" ? "none" : "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Password
              </Typography>
              <FormControl variant="outlined" sx={styles.textField} fullWidth>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      required
                      {...field}
                      size={styles.textfieldSize}
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: "black" }}
                            >
                              {showPassword ? (
                                <VisibilityOffOutlined />
                              ) : (
                                <VisibilityOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Confirm Password
              </Typography>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    size={styles.textfieldSize}
                    placeholder="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    sx={styles.textField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ color: "black" }}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "#9A9FBF", fontSize: 13 }}>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                Eight (8) or more alphabetical characters
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                one (1) or more uppercase character(s)
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                One (1) or more numerical character(s)
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                One (1) or more special character(s)
              </ListItem>
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              pb: 1,
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Role
              </Typography>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <FormControl
                    // variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <Select {...field} error={!!errors.role}>
                      <MenuItem value={roles.MANAGER}>Manager</MenuItem>
                      <MenuItem value={roles.CHECKER}>Checker</MenuItem>
                      <MenuItem value={roles.APPROVER}>Approver</MenuItem>
                      <MenuItem value={roles.FINANCIAL_MANAGER}>
                        Financial Manager
                      </MenuItem>
                    </Select>
                    {errors.role ? (
                      <FormHelperText error>
                        {errors?.role?.message}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                )}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography sx={{ fontWeight: 400, fontSize: 14, pb: 1 }}>
                Gender
              </Typography>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <FormControl
                    // variant="outlined"
                    size="small"
                    fullWidth
                  >
                    <Select {...field} error={!!errors.gender}>
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                    </Select>
                    {errors.gender ? (
                      <FormHelperText error>
                        {errors?.gender?.message}
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{ color: "#fff", textTransform: "capitalize", fontSize: 14 }}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      )}
      <InfoDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        text={infoText}
        error={isError}
      />
    </>
  );
};
