import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getAllCollections = (params) => {
  return client.get(`collection`, { params });
};

export const getAllCollectionsForExport = (params) => {
  return client.get(`collection/all`, { params });
};

export const getCollectionTransactionInfo = (id) => {
  return client.get(`transactions/${id}`);
};
