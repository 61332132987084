import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DisbursementsTable } from "./DisbursementsTable";
import { FilterSection } from "../../common/components/FilterSection";
import {
  fetchDisbursement,
  fetchDisbursementExportData,
  fetchDisbursements,
  searchDisbursementsTransactions,
  searchDisbursementsTransactionsByDate,
} from "./disbursementsSlice";
import { format } from "date-fns";
import { exportToExcel } from "react-json-to-excel";
import {
  updateDateFilter,
  updateSearchFilter,
} from "../../common/store/filterSlice";

export const Disbursements = () => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const disbursements = useSelector((state) => state.disbursements);
  const filter = useSelector((state) => state.filter);

  const dispatch = useDispatch();

  const handleViewClick = (uid) => {
    dispatch(fetchDisbursement(uid));
    setOpen(true);
  };

  const handleCloseView = () => setOpen(false);

  const handleSearch = (searchTerm) => {
    dispatch(updateSearchFilter(searchTerm));
    dispatch(searchDisbursementsTransactions({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(
      searchDisbursementsTransactionsByDate({
        searchTerm: filter.searchTerm,
        page: newPage,
        ...(filter.startDate && {
          startDate: filter.startDate,
        }),
        ...(filter.endDate && {
          endDate: filter.endDate,
        }),
      })
    );
  };

  const onFilterSubmit = (data) => {
    dispatch(updateDateFilter(data));
    dispatch(
      searchDisbursementsTransactionsByDate({
        searchTerm: filter.searchTerm,
        page: 1,
        ...data,
        startDate: format(data.startDate, "yyyy-MM-dd"),
        endDate: format(data.endDate, "yyyy-MM-dd"),
      })
    );
  };

  const handleExport = (data) => {
    setIsLoading(true);
    dispatch(
      fetchDisbursementExportData({
        searchTerm: document.getElementById("searchTerm").value,
        startDate: data.startDate
          ? format(data.startDate, "yyyy-MM-dd")
          : filter.startDate,
        endDate: data.endDate
          ? format(data.endDate, "yyyy-MM-dd")
          : filter.endDate,
      })
    ).then((result) => {
      if (fetchDisbursementExportData.fulfilled.match(result)) {
        const csvData = result.payload.data.disbursements.reduce(
          (res, item) => {
            res.push({
              disbursementID: item.uid,
              recipientAccountName: item.accountName,
              phoneNumber: item.phoneNumber,
              narration: item.narration,
              amount: item.disbursementAmount,
              timestamp: format(
                new Date(item.disbursementDate),
                "dd/MM/yyyy hh:mm:ss aa"
              ),
              transactionID: item.transactionId,
              status: item.status,
            });
            return res;
          },
          []
        );

        exportToExcel(csvData, "Disbursements");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(fetchDisbursements(1));
  }, []);

  return (
    <>
      <SectionHeader headerText="Disbursements" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection
              onSubmit={onFilterSubmit}
              handleExport={handleExport}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Stack>
          <DisbursementsTable
            disbursements={disbursements}
            page={page}
            handlePageChange={handlePageChange}
            open={open}
            handleViewClick={handleViewClick}
            handleCloseView={handleCloseView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
