import { createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";

const initialState = {
  searchTerm: "",
  startDate: "",
  endDate: "",
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    updateDateFilter: (state, action) => {
      state.startDate = format(action.payload.startDate, "yyyy-MM-dd");
      state.endDate = format(action.payload.endDate, "yyyy-MM-dd");
    },
    updateSearchFilter: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { updateDateFilter, updateSearchFilter } = filterSlice.actions;
export default filterSlice.reducer;
