import { Box } from "@mui/material";
import React, { useState } from "react";
// import { BVN } from "../../components/documentFilters/BVN";
// import {BVN} from "../../../../"
import { GovernmentID } from "../../kyc/components/documentFilters/GovernmentID";
import { AddressVerification } from "../../kyc/components/documentFilters/AddressVerification";
// import { BVN } from "../../kyc/components/documentFilters/BVN";
import { NIN } from "../../loans/details/personal/stages/NIN";

// const documentFilter = [
//   { section: "BVN", label: "BVN" },
//   { section: "GovernmentID", label: "Government ID" },
//   { section: "AddressVerification", label: "Address Verification" },
// ];

export const Documents = ({ customer, kycData, bvnData, ninData }) => {
  const [selectedSection, setSelectedSection] = useState("BVN");

  // const handleSectionButtonClick = (section) => {
  //   setSelectedSection(section);
  // };

  return (
    <Box sx={{ py: 4, width: "100%" }}>
      {/* Subsection Selectors */}
      {/* <Box sx={{ py: 2 }}>
        {documentFilter.map(({ section, label }) => (
          <Button
            key={section}
            variant="outlined"
            sx={{
              height: 30,
              textTransform: "capitalize",
              color: selectedSection === section ? "#000" : "#B5B5B5",
              borderColor: "#B5B5B5",
              borderRadius: 0,
              backgroundColor: selectedSection === section ? "" : "#F7F8F4",
              py: 2,
              fontSize: 12,
            }}
            onClick={() => handleSectionButtonClick(section)}
          >
            {label}
          </Button>
        ))}
      </Box> */}

      {selectedSection === "BVN" && (
        <NIN customer={customer} kycData={kycData} bvnData={bvnData} />
      )}
      {selectedSection === "GovernmentID" && (
        <GovernmentID customer={customer} kycData={kycData} ninData={ninData} />
      )}
      {selectedSection === "AddressVerification" && (
        <AddressVerification kycData={kycData} />
      )}
    </Box>
  );
};
