import { Box, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../../../common/css/styles";
import { businessInfoStage } from "./stages";
import { Details } from "./stages/Details";
import { Profile } from "./stages/Profile";
import { Operations } from "./stages/Operations";
import { Collateral } from "./stages/Collateral";
import { OwnerInfo } from "../../../../business/kyb/ownerInfo/OwnerInfo";
import { Guarantors } from "./stages/Guarantors";
import { Documents } from "./stages/Documents";
import { useSmeLoans } from "../../../../loans/hooks/smeloans";
import { WhiteBox } from "../../../../../common/components/WhiteBox";
import { useQuery } from "@tanstack/react-query";
import { getBusiness } from "../../../../../common/api/businesses";
import { BankStatement } from "../../../../loans/details/personal/stages/BankStatement";

export const BusinessInfo = ({ businessLoan }) => {
  const { businessId, loanUid } = businessLoan;

  const {
    businessTeamMembers,
    businessDocuments,
    anyLoading,
    businessCollateral,
    businessGuarantors,
  } = useSmeLoans(loanUid, businessId);

  const { data: businesInfoData, isLoading: businesInfoIsLoading } = useQuery({
    queryKey: [businessId, `business${businessId}`],
    queryFn: () => getBusiness(businessId),
    refetchOnWindowFocus: false,
  });
  const businesInfo = businesInfoData?.data?.data ?? {};

  const [activeFilter, setActiveFilter] = useState(businessInfoStage.DETAILS);

  const filters = [
    { label: "Details", value: businessInfoStage.DETAILS, visible: true },
    { label: "Profile", value: businessInfoStage.PROFILE, visible: true },
    {
      label: "Operations",
      value: businessInfoStage.OPERATIONS,
      visible: true,
    },
    {
      label: "Owner Info",
      value: businessInfoStage.OWNER_INFO,
      visible: true,
    },
    {
      label: "Guarantors",
      value: businessInfoStage.GUARANTORS,
      visible: true,
    },
    {
      label: "Collateral",
      value: businessInfoStage.COLLATERAL,
      visible: true,
    },
    {
      label: "Documents",
      value: businessInfoStage.DOCUMENTS,
      visible: true,
    },

    {
      label: "Bank Statement",
      value: businessInfoStage.BANK_STATEMENT,
      visible: true,
    },
  ];

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getBusinessInfoStage = () => {
    switch (activeFilter) {
      case businessInfoStage.DETAILS:
        return <Details data={businesInfo} />;
      case businessInfoStage.PROFILE:
        return <Profile data={businesInfo} loading={businesInfoIsLoading} />;
      case businessInfoStage.OPERATIONS:
        return <Operations data={businesInfo} loading={businesInfoIsLoading} />;
      case businessInfoStage.OWNER_INFO:
        // return (
        //   <TeamMembers teamMembers={teamMembers} handleView={handleView} />
        // );
        return <OwnerInfo data={businessTeamMembers} />;
      case businessInfoStage.COLLATERAL:
        return <Collateral data={businessCollateral} />;
      case businessInfoStage.GUARANTORS:
        return <Guarantors data={businessGuarantors} />;
      case businessInfoStage.DOCUMENTS:
        return <Documents data={businessDocuments} />;
      case businessInfoStage.BANK_STATEMENT:
        return <BankStatement />;

      default:
        return <Details data={businessLoan} />;
    }
  };

  if (anyLoading)
    return (
      <WhiteBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ alignItems: "center" }} />
        </Box>
      </WhiteBox>
    );

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      {getBusinessInfoStage()}
    </Box>
  );
};
