import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getLoanBankStatement } from "../../../../../common/api/loans";
import { DeadState } from "../../../../../common/components/DeadState";
import {
  formatCurrencyvalue,
  handleDownloadFile,
} from "../../../../../common/util";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";

export const BankStatement = () => {
  const { loanid } = useParams();
  console.info(loanid);
  const { data, isLoading } = useQuery({
    queryKey: [loanid, "loanBankStatement"],
    queryFn: () => getLoanBankStatement(loanid),

    refetchOnWindowFocus: false,
  });

  const { statement, type } = data?.data?.data ?? {};
  const bankstatement =
    type === "ELECTRONIC" && statement ? JSON.parse(statement) : {};

  // data ?? {};

  const details = bankstatement.Details ?? [];

  const listItems = [
    "Name",
    "Nuban",
    "AccountCategory",
    "AccountType",
    "TicketNo",
    "AvailableBal",
    "BookBal",
    "TotalCredit",
    "TotalDebit",
    "Tenor",
    "Period",
    "Currency",
    "Address",
  ];

  const columns = [
    {
      id: "TransactionDate",
      label: "TransactionDate",
      minWidth: 50,
      align: "left",
    },
    {
      id: "ValueDate",
      label: "ValueDate",
      minWidth: 50,
      align: "left",
    },
    {
      id: "Narration",
      label: "Narration",
      minWidth: 50,
      align: "left",
    },
    {
      id: "Balance",
      label: "Credit",
      minWidth: 50,
      align: "left",
    },
    {
      id: "Debit",
      label: "Debit",
      minWidth: 10,
      align: "left",
    },
    {
      id: "Balance",
      label: "Balance",
      minWidth: 10,
      align: "left",
    },
  ];

  return (
    <Box
      sx={{
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : type === "FILE" ? (
        <>
          <LoadingButton
            sx={{
              border: 1,
              py: 1,
              px: 2,
              borderColor: "#DFF3E7",
              borderRadius: 2,
              backgroundColor: "#F5FAF7",
              textTransform: "none",
            }}
            onClick={() => handleDownloadFile(statement, "cac.png")}
          >
            <DownloadIcon /> Download
          </LoadingButton>
        </>
      ) : (
        <>
          <Grid container>
            {listItems.map((item, i) => (
              <Grid key={i} item xs={12} md={6}>
                <Box sx={{ py: 1, display: "flex" }}>
                  <Typography sx={{ fontWeight: 400, color: "#62635E", mr: 1 }}>
                    {item}:
                  </Typography>
                  <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
                    {bankstatement[item]}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
              mt: 5,
            }}
          >
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ backgroundColor: "transparent" }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        color: "#000",
                        backgroundColor: "transparent",
                        border: "4px solid black",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          borderTop: "1px solid #B5B5B5",
                          borderLeft: "none",
                          borderRight: "none",
                          borderBottom: "1px solid #B5B5B5",
                          fontSize: 13,
                          fontWeight: 600,
                          height: 20,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.length > 0 ? (
                    details?.map((bank) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={bank.uid}
                        sx={{
                          "& .MuiTableCell-body": {
                            color: "#62635E",
                            backgroundColor: "#fff",
                            border: "none",
                            fontSize: 13,
                            fontWeight: 400,
                          },
                        }}
                      >
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PTransactionDate ?? "Not available"}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PValueDate ?? "Not available"}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PNarration ?? "Not available"}
                        </TableCell>

                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PCredit
                            ? formatCurrencyvalue(bank.PCredit)
                            : "Not available"}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PDebit
                            ? formatCurrencyvalue(bank.PDebit)
                            : "Not available"}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {bank.PBalance
                            ? formatCurrencyvalue(bank.PBalance)
                            : "Not available"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      colSpan={columns.length}
                      sx={{ textAlign: "center" }}
                    >
                      <DeadState />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </Box>
  );
};
