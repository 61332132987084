import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../common/css/styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { handleErrorAlt } from "../../common/constants/utility";
import { LoadingButton } from "@mui/lab";
import DisplayError from "../../common/components/DisplayError";
import { unAuthClient } from "../../common/api";

const defaultValues = {
  recipient: "",
};

const schema = yup.object().shape({
  recipient: yup
    .string()
    .email("Please enter a valid email")
    .required("Email address is required"),
});

export const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const parseError = (error) => {
    console.info({ error });
    if (error.response?.status == 401) return "Email not found";
    return "Oops! An error occured. Please contact support";
  };

  const { isLoading, mutate, error } = useMutation({
    mutationFn: (data) =>
      unAuthClient.post(
        `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
        data
      ),
    mutationKey: "completeLoan",
    onError: (err) => {
      handleErrorAlt(err, dispatch);
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    },
    onSuccess: (data) => {
      navigate("/set-password", {
        state: {
          email: getValues("recipient"),
        },
      });
    },
  });

  const onSubmit = (data) => {
    mutate({ ...data, operation: "RESET_PASSWORD" });
  };

  const backToLogin = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: 2,
          py: 4,
          px: 4,
          justifyContent: "center",
        }}
      >
        <Grid
          container
          item
          position="relative"
          direction="column"
          alignItems="center"
        >
          <Box
            container
            item
            md={6}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              backgroundColor: "primary.main",
              ml: -1,
              pt: 1,
              pb: 1,
              borderRadius: "30px",
              position: "absolute",
              top: -53,
              pl: 2.5,
              pr: 2.5,
            }}
          >
            <Typography sx={{ color: "white", width: "100%" }}>
              Login
            </Typography>
          </Box>
        </Grid>

        <Typography
          variant="h6"
          sx={{ ...styles.formHeader, textAlign: "center" }}
        >
          Reset Password
        </Typography>
        <Grid
          container
          direction={"column"}
          spacing={3}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pt={1}
        >
          <Grid item></Grid>
          <Grid item>
            <Typography variant="body1" sx={styles.formFieldHeading}>
              Email
            </Typography>
            <Controller
              name="recipient"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size={styles.textfieldSize}
                  required
                  fullWidth
                  placeholder="e.g. johndoe@gmail.com"
                  error={!!errors.recipient}
                  helperText={errors.recipient?.message}
                  sx={styles.textField}
                />
              )}
            />
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <LoadingButton
              variant="contained"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              sx={styles.continueButton2}
              fullWidth
              loading={isLoading}
            >
              Reset
            </LoadingButton>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            sx={{ mt: -2 }}
          >
            <Button
              sx={{
                fontWeight: 600,
                fontSize: 14,
                textTransform: "capitalize",
                p: 0,
                pt: 2,
                color: "#000",
              }}
              onClick={backToLogin}
            >
              Back to
              <span style={{ color: "#19AD42", paddingLeft: "5px" }}>
                Login
              </span>
            </Button>
          </Grid>
        </Grid>
        <DisplayError
          error={error ? parseError(error) : ""}
          open={showError}
          handleClose={() => setShowError(false)}
        />
      </Box>
    </>
  );
};
