import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getStatistics = (startDate, endDate) => {
  return client.get(`admin-overview?startDate=${startDate}&endDate=${endDate}`);
};

export const getLoanMetrics = (startDate, endDate, status) => {
  return client.get(
    `loan-metrics?startDate=${startDate}&endDate=${endDate}&status=${status}`
  );
};

export const getPendingCustomers = (startDate, endDate, page) => {
  return client.get(
    `customers/pending?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`
  );
};

export const getPendingLoans = (startDate, endDate, page, filterOption) => {
  return client.get(
    `pending-loans?startDate=${startDate}&endDate=${endDate}&filterBy=${filterOption}&page=${page}&size=${size}`
  );
};
export const getCustomerStats = (startDate, endDate) => {
  return client.get(
    `dashboard/customer-stats?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getLoanStats = (startDate, endDate, type) => {
  return client.get(
    `dashboard/loan-stats?loanType=${type}&startDate=${startDate}&endDate=${endDate}`
  );
};
export const getLoanStatsMetrics = (startDate, endDate, type, status) => {
  return client.get(
    `dashboard/loan-portfolio-metrics?loanType=${type}&startDate=${startDate}&endDate=${endDate}&status=${status}`
  );
};
export const getLoanStatsInsights = (
  startDate,
  endDate,
  type,
  status,
  page
) => {
  return client.get(
    `dashboard/loan-insight?loanType=${type}&startDate=${startDate}&endDate=${endDate}&status=${status}&page=${page}&size=${size}`
  );
};

export const getLoanStatsInsightsForExport = (
  startDate,
  endDate,
  type,
  status
) => {
  return client.get(
    `dashboard/loan-insight/full?loanType=${type}&startDate=${startDate}&endDate=${endDate}&status=${status}`
  );
};
