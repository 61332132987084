import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDisbursement,
  getDisbursements,
  getDisbursementsExportData,
  searchDisbursements,
  searchDisbursementsByDate,
} from "../../common/api/disbursements";
import { handleError } from "../../common/constants/utility";
import { getTransaction } from "../../common/api/wallets";

const initialState = {
  transactions: [],
  transaction: null,
  loading: false,
  loadingTransaction: false,
  totalRecords: 0,
  error: [],
};

export const fetchDisbursementExportData = createAsyncThunk(
  "disbursements/export",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getDisbursementsExportData(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchDisbursements = createAsyncThunk(
  "disbursements/fetch/all",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await getDisbursements(page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchDisbursement = createAsyncThunk(
  "disbursements/fetch",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getTransaction(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchDisbursementsTransactions = createAsyncThunk(
  "disbursements/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchDisbursements(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchDisbursementsTransactionsByDate = createAsyncThunk(
  "disbursements/searchByDate",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchDisbursementsByDate(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const disbursementsSlice = createSlice({
  name: "disbursements",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDisbursements.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDisbursements.fulfilled, (state, action) => {
      state.transactions = action.payload.data.disbursements;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchDisbursements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(
      searchDisbursementsTransactions.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      searchDisbursementsTransactions.fulfilled,
      (state, action) => {
        state.transactions = action.payload.data.disbursements;
        state.totalRecords = action.payload.data.totalRecords;
        state.loading = false;
        state.error = [];
      }
    );
    builder.addCase(
      searchDisbursementsTransactions.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
    builder.addCase(fetchDisbursement.pending, (state, action) => {
      state.loadingTransaction = true;
    });
    builder.addCase(fetchDisbursement.fulfilled, (state, action) => {
      state.transaction = action.payload.data;
      state.loadingTransaction = false;
      state.error = [];
    });
    builder.addCase(fetchDisbursement.rejected, (state, action) => {
      state.loadingTransaction = false;
      state.error = action.payload;
    });
    builder.addCase(
      searchDisbursementsTransactionsByDate.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      searchDisbursementsTransactionsByDate.fulfilled,
      (state, action) => {
        state.transactions = action.payload.data.disbursements;
        state.totalRecords = action.payload.data.totalRecords;
        state.loading = false;
        state.error = [];
      }
    );
    builder.addCase(
      searchDisbursementsTransactionsByDate.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default disbursementsSlice.reducer;
