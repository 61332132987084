import React, { useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { styles } from "../../common/css/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { overviewFilters } from "../overview/overviewFilters";
import { loanType } from "../smeLoans/constants/loanType";
import { useQuery } from "@tanstack/react-query";
import { getLoans } from "../../common/api/loans";
import CustomDate from "../../common/components/CustomDate";
import dayjs from "dayjs";
import { useLoanParams } from "../overview/hooks/useLoanParams";
import { LoansTableNew } from "./LoansTableNew";
import { loanStatusFilters } from "./constants/loanFilters";
import { useSelector } from "react-redux";

export const Loans = () => {
  const { getDateRange } = useLoanParams();
  const role = useSelector(({ auth }) => auth.user?.roles?.[0]?.name);
  const search = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams(search);
  const [searchTerm, setsearchTerm] = useState("");
  const [loanTypeFilter, setLoanTypeFilter] = useState(
    searchParams.get("loanTypeFilter") ?? "ALL"
  );
  const [loanStatusFilter, setLoanStatusFilter] = useState(
    searchParams.get("status") ?? loanStatusFilters.ALL
  );
  const [page, setPage] = useState(1);

  const [customFilter, setCustomFilter] = useState({
    open: false,
    value: searchParams.get("startDate")
      ? overviewFilters.CUSTOM
      : overviewFilters.LAST7DAYS,
    ...getDateRange(overviewFilters.LAST7DAYS),
  });

  const params = {};
  searchParams.forEach((val, key) => {
    params[key] = val;
  });

  const [query, setQuery] = useState({
    page,
    loanTypeFilter: "ALL",
    status: "ALL",
    ...getDateRange(overviewFilters.LAST7DAYS),
    ...params,
  });

  const queryFn = () => {
    const { loanTypeFilter, searchTerm, ...others } = query;
    // if (loanTypeFilter == loanType.BUSINESS) return getSmeLoans(others);
    // return
    return getLoans({
      ...others,
      loanType: loanTypeFilter,
      search: searchTerm ?? "",
    });
  };

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["loans", query],
    queryFn: () => queryFn(),
    refetchOnWindowFocus: false,
  });

  const retailLoans = data?.data.data ?? {};

  const navigate = useNavigate();

  const handleSearch = (searchTerm) => {
    setPage(1);
    const queryData = { ...query, page: 1, searchTerm };
    setQuery(queryData);
    setSearchParams(queryData);
    setsearchTerm(searchTerm);
  };

  const handleLoanStatusFilterChange = (event) => {
    setPage(1);
    setLoanStatusFilter(event.target.value);
    const queryData = { ...query, page: 1, status: event.target.value };
    setQuery(queryData);
    setSearchParams(queryData);
  };

  const handleLoanTypeFilterChange = (event) => {
    setPage(1);
    setLoanTypeFilter(event.target.value);
    const queryData = { ...query, page: 1, loanTypeFilter: event.target.value };
    setQuery(queryData);
    setSearchParams(queryData);
  };
  const handleFilterChange = (value) => {
    if (value === "CUSTOM") {
      setCustomFilter({ ...query, value: value, open: true });
    } else {
      setCustomFilter({ value: value });
      const queryData = { ...query, ...getDateRange(value) };
      setQuery(queryData);
      setSearchParams(queryData);
    }
  };
  const handleCustomFilter = (name, value) => {
    setCustomFilter({
      ...customFilter,
      [name]: dayjs(value.$d).format("YYYY-MM-DD"),
    });
  };

  const applyCustomFilter = () => {
    setCustomFilter({ ...customFilter, open: false });
    setPage(1);
    const queryData = {
      ...query,
      startDate: customFilter.startDate,
      endDate: customFilter.endDate,
    };

    setQuery(queryData);
    setSearchParams(queryData);
  };

  const handlePageChange = (event, newPage) => {
    const queryData = { ...query, page: newPage };
    setPage(newPage);
    setQuery(queryData);
    setSearchParams(queryData);
  };
  const filters = [
    {
      label: "All",
      value: loanStatusFilters.ALL,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 1,
      },
    },
    {
      label: "Pending Recommendation",
      value: loanStatusFilters.PENDING_RECOMMENDATION,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "Pending Approval",
      value: loanStatusFilters.PENDING_APPROVAL,
      visible: true,
      for: loanType.RETAIL,
      loanTypes: {
        RETAIL: 0,
        BRIDGE_FINANCING: 0,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "Pending Authorization",
      value: loanStatusFilters.PENDING_AUTHORIZATION,
      visible: true,
      for: loanType.BRIDGE_FINANCING,
      loanTypes: {
        RETAIL: 0,
        BRIDGE_FINANCING: 0,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "Pending Lien",
      value: loanStatusFilters.PENDING_LIEN,
      visible: true,
      for: loanType.BRIDGE_FINANCING,
      loanTypes: {
        RETAIL: 0,
        BRIDGE_FINANCING: 1,
        ASSET: 0,
        BUSINESS: 0,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    // {
    //   label: "Pending Review",
    //   value: loanStatusFilters.PENDING_REVIEW,
    //   visible: true,
    //   for: loanType.BRIDGE_FINANCING,
    //   loanTypes: {
    //     RETAIL: 0,
    //     BRIDGE_FINANCING: 0,
    //     ASSET: 0,
    //     BUSINESS: 0,
    //   },
    //   roles: {
    //     CHECKER: 1,
    //     MANAGER:1,
    //     APPROVER: 1,
    //     FINANCIAL_MANAGER: 0,
    //   },
    // },
    {
      label: "Pending Offer",
      value: loanStatusFilters.PENDING_OFFER,
      visible: true,
      for: loanType.BRIDGE_FINANCING,
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },

    {
      label: "Pending Disbursement",
      value: loanStatusFilters.PENDING_DISBURSEMENT,
      visible: true,
      for: loanType.BRIDGE_FINANCING,
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 0,
        MANAGER: 0,
        APPROVER: 0,
        FINANCIAL_MANAGER: 1,
      },
    },

    {
      label: "Pay Interest",
      value: loanStatusFilters.PAY_INTEREST,
      visible: true,
      for: loanType.BRIDGE_FINANCING,
      loanTypes: {
        RETAIL: 0,
        BRIDGE_FINANCING: 1,
        ASSET: 0,
        BUSINESS: 0,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },

    // ............

    {
      label: "Offered",
      value: loanStatusFilters.OFFERED,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 0,
        BUSINESS: 0,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    // {
    //   label: "Offer Accepted",
    //   value: loanStatusFilters.OFFER_ACCEPTED,
    //   visible: true,
    //   for: "ALL",
    //   loanTypes: {
    //     RETAIL: 0,
    //     BRIDGE_FINANCING: 0,
    //     ASSET: 0,
    //     BUSINESS: 0,
    //   },
    //   roles: {
    //     CHECKER: 1,
    //     MANAGER: 1,
    //     APPROVER: 1,
    //     FINANCIAL_MANAGER: 0,
    //   },
    // },

    {
      label: "Active",
      value: loanStatusFilters.ACTIVE,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "Rejected Offers",
      value: loanStatusFilters.OFFER_REJECTED,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },

    {
      label: "Declined",
      value: loanStatusFilters.DECLINED,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },

    {
      label: "Defaulting",
      value: loanStatusFilters.DEFAULTING,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },

    {
      label: "Completed",
      value: loanStatusFilters.COMPLETED,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "NTUs",
      value: loanStatusFilters.NTU,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
    {
      label: "Lost",
      value: loanStatusFilters.LOST,
      visible: true,
      for: "ALL",
      loanTypes: {
        RETAIL: 1,
        BRIDGE_FINANCING: 1,
        ASSET: 1,
        BUSINESS: 1,
      },
      roles: {
        CHECKER: 1,
        MANAGER: 1,
        APPROVER: 1,
        FINANCIAL_MANAGER: 0,
      },
    },
  ];

  const filtersToShow = filters.filter((v) =>
    loanTypeFilter === "ALL"
      ? v.roles[role]
      : v.roles[role] && v.loanTypes[loanTypeFilter]
  );

  const loanTypes = [
    { label: "All Loan Types", value: "ALL" },
    {
      label: "Bridge Financing",
      value: loanType.BRIDGE_FINANCING,
      excludeRole: "APPROVER",
    },
    { label: "Retail Loans", value: loanType.RETAIL, excludeRole: "APPROVER" },
    {
      label: "Business Loans",
      value: loanType.BUSINESS,
      excludeRole: "CHECKER",
    },
    {
      label: "Asset Financing",
      value: loanType.ASSET_FINANCING,
      excludeRole: "CHECKER",
    },
  ];

  const loanTypesToDisplay = loanTypes.filter((v) => v.excludeRole !== role);

  const ranges = [
    { label: "Last 7 days", value: overviewFilters.LAST7DAYS },
    {
      label: "Last 14 days",
      value: overviewFilters.LAST14DAYS,
    },
    { label: "Last 1 month", value: overviewFilters.ONE_MONTH },
    { label: "Last 2 months", value: overviewFilters.TWO_MONTHS },
    { label: "Last 3 months", value: overviewFilters.THREE_MONTHS },
    { label: "Custom Date", value: overviewFilters.CUSTOM },
  ];

  // const handleView = (uid) => navigate(`/loans/overview/${uid}`);
  const handleView = (uid, loanType) =>
    navigate(`/loans/loan-details/${loanType}/${uid}`);
  return (
    <>
      <SectionHeader headerText="Loans" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanTypeFilter}
                  size="small"
                  onChange={handleLoanTypeFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {loanTypesToDisplay.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ mr: 2.5, mt: 1 }}>
                <Select
                  value={loanStatusFilter}
                  size="small"
                  onChange={handleLoanStatusFilterChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {filtersToShow.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ mt: 1 }}>
                <Select
                  value={customFilter.value}
                  size="small"
                  // onChange={ handleFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={styles.dropdown}
                >
                  {ranges.map((range) => (
                    <MenuItem
                      onClick={() => handleFilterChange(range.value)}
                      key={range.value}
                      value={range.value}
                    >
                      {range.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {customFilter.open && (
                <CustomDate
                  handleCustomFilter={handleCustomFilter}
                  customFilter={customFilter}
                  close={() =>
                    setCustomFilter({ ...customFilter, open: false })
                  }
                  applyCustomFilter={applyCustomFilter}
                />
              )}
            </Box>
          </Stack>
          <LoansTableNew
            retailLoans={retailLoans}
            loanStatus={loanStatusFilter}
            selectedLoanType={loanTypeFilter}
            search={searchTerm}
            page={page - 1}
            handlePageChange={handlePageChange}
            handleView={handleView}
            loading={isLoading || isRefetching}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
