import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { DeadState } from "../../common/components/DeadState";
import { format } from "date-fns";
import { PageNavigation } from "../../common/components/PageNavigation";
import { TransactionDetails } from "./components/TransactionDetails";

const columns = [
  { id: "transactionID", label: "Transaction ID" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "narration", label: "Narration" },
  { id: "amount", label: "Amount" },
  { id: "date", label: "Timestamp" },
  { id: "action", label: "Action", visible: true },
];
export const WalletTransactionsTable = ({
  wallets,
  page,
  handlePageChange,
  open,
  handleViewClick,
  handleCloseView,
}) => {
  const transactions = wallets.transactions;
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Paper
        sx={{
          boxShadow: "none",
          pt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns.map((column) => (
                <TableCell key={column.id} sx={{ pr: 0 }}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleSort(column.id)}
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-body": {
                color: "#000",
                backgroundColor: "#fff",
                borderBottom: 0.5,
                borderColor: "#F7F8F4",
              },
            }}
          >
            {wallets.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : transactions.length > 0 ? (
              transactions.map((row) => (
                <TableRow key={row.uid}>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography noWrap sx={styles.table.text.uid}>
                      {row.uid}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography noWrap sx={styles.table.text}>
                      {row.user.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography noWrap sx={styles.table.text}>
                      {row.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography noWrap sx={styles.table.text}>
                      {row.user.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography sx={styles.table.text}>
                      {row.narration}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography
                      noWrap
                      sx={{
                        ...styles.table.text,
                        color: row.type === "DEBIT" ? "red" : "green",
                      }}
                    >
                      {row.type === "DEBIT" ? "-" : null}
                      {row.amount.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Typography noWrap sx={styles.table.text}>
                      {format(
                        new Date(row.updatedAt),
                        "dd/MM/yyyy hh:mm:ss aa"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pr: 0 }}>
                    <Button
                      variant="outlined"
                      onClick={() => handleViewClick(row.uid)}
                      sx={styles.table.text.viewButton}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
        {!wallets.loading && transactions.length > 0 ? (
          <PageNavigation
            page={page}
            totalRecords={wallets.totalRecords}
            handlePageChange={handlePageChange}
          />
        ) : (
          <></>
        )}

        <TransactionDetails
          open={open}
          handleClose={handleCloseView}
          transaction={wallets.transaction}
          loadingTransaction={wallets.loadingTransaction}
        />
      </Paper>
    </div>
  );
};
