import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { parse, parseISO } from "date-fns";
import { Button, TextField, Typography } from "@mui/material";
import { styles } from "../css/styles";
import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const defaultValues = {
  startDate: null,
  endDate: null,
};

const schema = yup.object().shape({
  startDate: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(parseISO(originalValue), "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid start date")
    .required("Start date is required"),
  endDate: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(parseISO(originalValue), "MM/dd/yyyy", new Date());
      return result;
    })
    .typeError("Please enter a valid end date")
    .required("End date is required"),
});

export const FilterSection2 = ({
  exportDataToCsv,
  csvLoading,
  handleDateFilter,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: 40,
      }}
    >
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(handleDateFilter)}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat="MM/dd/yyyy"
                sx={{ ...styles.newDatePicker, width: 170 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.startDate}
                    helperText={errors.startDate?.message}
                    autoComplete="off"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <Typography mx={1}>-</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat="MM/dd/yyyy"
                sx={{ ...styles.newDatePicker, width: 170 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.endDate}
                    helperText={errors.endDate?.message}
                    autoComplete="off"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <Button
          variant="outlined"
          type="submit"
          sx={{
            textTransform: "capitalize",
            color: "#000",
            backgroundColor: "#B5B5B54D",
            height: 40,
            ml: 1,
          }}
        >
          Apply
        </Button>
      </Box>

      <LoadingButton
        loading={csvLoading}
        variant="contained"
        onClick={exportDataToCsv}
        sx={{
          color: "#fff",
          ml: 3,
          textTransform: "capitalize",
          height: 40,
          width: 130,
          fontSize: 12,
          boxShadow: "none",
        }}
      >
        Export CSV <Download sx={{ fontSize: 12, ml: 1.5 }} />{" "}
      </LoadingButton>
    </Box>
  );
};
