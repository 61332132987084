import React, { useState } from "react";
import { TextField, IconButton, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Search } from "@mui/icons-material";

export const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Grid>
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search by First name, Last name, Email, Phone number "
        id="searchTerm"
        fullWidth
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          startAdornment: (
            <IconButton
              onClick={handleSearch}
              edge="end"
              sx={{ ml: -1, mr: 0.6, "&:hover": { backgroundColor: "none" } }}
            >
              <SearchIcon />
            </IconButton>
          ),
          style: {
            color: "#B5B5B5",
            backgroundColor: "#F7F8F4",
          },
        }}
        sx={{
          width: { md: "150%", xs: "100%", color: "#000" },
          my: 2,
          "& fieldset": {
            border: "none",
            color: "#000",
            borderRadius: 2,
          },
        }}
      />
    </Grid>
  );
};
