import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleError } from "../../../common/constants/utility";
import {
  getBusiness,
  getContactInfo,
  getDocuments,
  getOwnerInfo,
  updateBusinessStatus,
} from "../../../common/api/businesses";

const initialState = {
  businessInfo: null,
  contactInfo: null,
  ownerInfo: null,
  documents: null,
  loading: false,
  error: [],
  feedback: "",
};

export const fetchContactInfo = createAsyncThunk(
  "business/fetchContactInfo",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getContactInfo(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchBusinessInfo = createAsyncThunk(
  "business/fetch",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getBusiness(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchOwnerInfo = createAsyncThunk(
  "business/fetchOwnerInfo",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getOwnerInfo(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchBusinessDocuments = createAsyncThunk(
  "business/fetchDocuments",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getDocuments(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const verifyOrDecline = createAsyncThunk(
  "business/verifyOrDecline",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateBusinessStatus(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    clearBusiness: (state) => {
      state.businessInfo = null;
      state.contactInfo = null;
      state.ownerInfo = null;
      state.documents = null;
      state.error = [];
    },
    clearBusinessFeedback: (state, action) => {
      state.feedback = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBusinessInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBusinessInfo.fulfilled, (state, action) => {
      state.businessInfo = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchBusinessInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchContactInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchContactInfo.fulfilled, (state, action) => {
      state.contactInfo = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchContactInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchOwnerInfo.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOwnerInfo.fulfilled, (state, action) => {
      state.ownerInfo = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchOwnerInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchBusinessDocuments.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchBusinessDocuments.fulfilled, (state, action) => {
      state.documents = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchBusinessDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { clearBusiness, clearBusinessFeedback } = businessSlice.actions;
export default businessSlice.reducer;
