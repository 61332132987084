import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { feedback } from "../../../common/constants/feedback";
import { handleError } from "../../../common/constants/utility";
import {
  disburse,
  getLoan,
  getLoanHistory,
  lienDocuments,
  offerLoan,
  recommendLoanOffer,
  setLoanOfferer,
  setLoanRecommender,
  uploadProofOfRepayment,
} from "../../../common/api/retail";
import { retailFilters } from "../constants/retailFilters";

const initialState = {
  loan: null,
  loading: false,
  loadingHistory: false,
  history: null,
  disbursed: "",
  feedback: "",
  error: [],
};

export const loanOptions = {
  RECOMMEND: "RECOMMEND",
  MAKE_OFFER: "MAKE_OFFER",
  VIEW: "VIEW",
};

export const fetchRetailLoan = createAsyncThunk(
  "retail/fetchLoan",
  async (uid, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoan(uid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setRecommender = createAsyncThunk(
  "retail/loan/setRecommender",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanRecommender(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const recommendOrDeclineOffer = createAsyncThunk(
  "retail/loan/recommendForOffer",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await recommendLoanOffer(model);
      dispatch(fetchRetailLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const setOfferer = createAsyncThunk(
  "retail/loan/setOfferer",
  async (loanUid, { dispatch, rejectWithValue }) => {
    try {
      const response = await setLoanOfferer(loanUid);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const makeOrDeclineOffer = createAsyncThunk(
  "retail/loan/offerLoan",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await offerLoan(model);
      dispatch(
        updateLoanDetails({
          status: model.isLoanOffered
            ? retailFilters.OFFERED
            : retailFilters.DECLINED,
        })
      );
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchRetailLoanHistory = createAsyncThunk(
  "retail/fetchLoanHistory",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLoanHistory(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const uploadLienDocs = createAsyncThunk(
  "retail/loan/lien-docs",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await lienDocuments(model);
      dispatch(fetchRetailLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const disburseLoan = createAsyncThunk(
  "retail/loan/disburse",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await disburse(model);
      dispatch(fetchRetailLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const closeLoan = createAsyncThunk(
  "retail/loan/close",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await uploadProofOfRepayment(model);
      dispatch(fetchRetailLoan(model.loanUid));
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const retailLoanSlice = createSlice({
  name: "retail/loan",
  initialState,
  reducers: {
    updateLoanDetails: (state, action) => {
      state.loan = { ...state.loan, ...action.payload };
      state.error = [];
    },
    clearLoanDetails: (state) => {
      state.loan = null;
      state.error = [];
    },
    clearLoanFeedback: (state) => {
      state.feedback = "";
      state.lienUpload = "";
      state.disbursed = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRetailLoan.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchRetailLoan.fulfilled, (state, action) => {
      state.loan = action.payload.data;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchRetailLoan.rejected, (state, action) => {
      state.loan = null;
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(setRecommender.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setRecommender.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(recommendOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(recommendOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(setOfferer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(setOfferer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(makeOrDeclineOffer.fulfilled, (state, action) => {
      state.error = [];
    });
    builder.addCase(makeOrDeclineOffer.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchRetailLoanHistory.pending, (state, action) => {
      state.loadingHistory = true;
      state.error = [];
    });
    builder.addCase(fetchRetailLoanHistory.fulfilled, (state, action) => {
      state.history = action.payload.data;
      state.loadingHistory = false;
      state.error = [];
    });
    builder.addCase(fetchRetailLoanHistory.rejected, (state, action) => {
      state.loadingHistory = false;
      state.error = action.payload;
    });
    builder.addCase(uploadLienDocs.pending, (state, action) => {
      state.lienUpload = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(uploadLienDocs.fulfilled, (state, action) => {
      state.lienUpload = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(uploadLienDocs.rejected, (state, action) => {
      state.lienUpload = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(disburseLoan.pending, (state, action) => {
      state.disbursed = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(disburseLoan.fulfilled, (state, action) => {
      state.disbursed = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(disburseLoan.rejected, (state, action) => {
      state.disbursed = feedback.FAILED;
      state.error = action.payload;
    });
    builder.addCase(closeLoan.pending, (state, action) => {
      state.feedback = feedback.PENDING;
      state.error = [];
    });
    builder.addCase(closeLoan.fulfilled, (state, action) => {
      state.feedback = feedback.SUCCESSFUL;
      state.error = [];
    });
    builder.addCase(closeLoan.rejected, (state, action) => {
      state.feedback = feedback.FAILED;
      state.error = action.payload;
    });
  },
});

export const { updateLoanDetails, clearLoanDetails, clearLoanFeedback } =
  retailLoanSlice.actions;
export default retailLoanSlice.reducer;
