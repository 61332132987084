import { Box, Typography, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../../common/css/styles";
import { businessDetailsFilters } from "../constants/businessFilters";
import { stage } from "./index";
// import { Bank } from "./Bank";
import { Documents } from "./Documents";
import avatarDummy from "../../../common/images/avatar-image.jpg";
import { BVN } from "../../kyc/components/documentFilters/BVN";
import { AddressVerification } from "../../kyc/components/documentFilters/AddressVerification";

export const Personal = ({ customer }) => {
  // const [currentStage, setCurrentStage] = useState(stage.PERSONAL);
  const [activeFilter, setActiveFilter] = useState(
    businessDetailsFilters.PERSONAL
  );

  const filters = [
    {
      label: "Personal",
      value: businessDetailsFilters.PERSONAL,
      visible: true,
    },
    {
      label: "Residence",
      value: businessDetailsFilters.RESIDENCE,
      visible: true,
    },
    {
      label: "Photo",
      value: businessDetailsFilters.PHOTO,
      visible: true,
    },
    {
      label: "BVN",
      value: businessDetailsFilters.BVN,
      visible: true,
    },
    {
      label: "ID Documents",
      value: businessDetailsFilters.IDENTIFICATION,
      visible: true,
    },
    {
      label: "Address Verification",
      value: businessDetailsFilters.ADDRESS_VERIFICATION,
      visible: true,
    },
  ];
  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getPersonalStage = () => {
    switch (activeFilter) {
      case stage.PERSONAL:
        return <PersonalDetails />;
      case stage.RESIDENCE:
        return <ResidenceDetails />;
      case stage.PHOTO:
        return <img src={avatarDummy} width={300} alt="avatar" />;
      case stage.BVN:
        return <BVN customer={null} data={null} />;
      case stage.IDENTIFICATION:
        return <Documents />;
      case stage.ADDRESS_VERIFICATION:
        return <AddressVerification kycData={null} />;
      default:
        return <PersonalDetails />;
    }
  };

  return (
    <Box>
      <Box sx={{ py: 3, display: "flex" }}>
        {filters
          .filter((filter) => filter.visible)
          .map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              variant="outlined"
              sx={
                activeFilter === filter.value
                  ? { ...styles.tableFilter.active, fontSize: 14, p: 2 }
                  : { ...styles.tableFilter.inactive, fontSize: 14, p: 2 }
              }
            >
              {filter.label}
            </Button>
          ))}
      </Box>
      <Box sx={{ width: "150%" }}>{getPersonalStage()}</Box>
    </Box>
  );
};

export const PersonalDetails = () => {
  const data = {};
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Full Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Charles
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Email
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              CharlesBand@gmail.com
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Last Name
            </Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              Bandenton
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              +234 810 222 2222
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const ResidenceDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Address
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              4th Mary high street
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              State
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Lagos
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              City
            </Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              Ikeja
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const EmploymentDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Company
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Longitech
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              City
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Ikeja
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Role
            </Typography>
            <Typography sx={styles.kyc.bodyText}>Finance Manager</Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Salary Range
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              N1,000,000 - N5,000,000
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Address
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              4thMary High street
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              State
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Lagos
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Date Joined
            </Typography>
            <Typography sx={styles.kyc.bodyText}>26/01/2020</Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Employment Status
            </Typography>
            <Typography sx={styles.kyc.bodyText}>Employed</Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const NextOfKinDetails = () => {
  const data = {};
  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "space-between",
        width: "100%",
      }}
      spacing={2}
      useFlexGap
    >
      <Box sx={{ mr: 25 }}>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Full Name
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              Charles Bandenton
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Phone Number
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.kinPhoneNumber ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Relationship
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinRelationship ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              LGA
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinCity ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Email Address
            </Typography>
            <Typography
              sx={{ ...styles.kyc.bodyText, textTransform: "lowercase" }}
            >
              {data?.kinEmail ? data.kinEmail.toLowerCase() : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              DOB
            </Typography>
            <Typography sx={{ fontWeight: 700, color: "#494D4A" }}>
              {data?.kinDateOfBirth?.replaceAll("-", "/") ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              Address
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinResidentialAddress ?? "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ py: 1 }}>
            <Typography sx={{ fontWeight: 400, color: "#62635E", py: 1 }}>
              State
            </Typography>
            <Typography sx={styles.kyc.bodyText}>
              {data?.kinState ?? "Not Available"}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
