import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Box,
  TableContainer,
  Paper,
  Typography,
  CircularProgress,
  Pagination,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { styles } from "../../common/css/styles";
import { DeadState } from "../../common/components/DeadState";
import { format } from "date-fns";
import { fetchUsers } from "./usersSlice";
import { useDispatch } from "react-redux";

export const UserManagementTable = ({ users, handleEdit }) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const usersList = users.users.filter(
    (user) => user.email !== "finance@krevus.com"
  );
  const rowsPerPage = parseInt(process.env.REACT_APP_PAGESIZE);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(fetchUsers({ page: newPage + 1, filterOption: "" }));
  };

  // const handleClick = (event, id) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedRow(id);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setSelectedRow(null);
  // };

  const columns = [
    { id: "firstName", label: "First Name", minWidth: 20, visible: true },
    { id: "lastName", label: "Last Name", minWidth: 20, visible: true },
    { id: "email", label: "Email", minWidth: 20, visible: true },
    { id: "phoneNo", label: "Phone Number", minWidth: 20, visible: true },
    { id: "date", label: "Date", minWidth: 20, visible: true },
    { id: "role", label: "Role", minWidth: 20, align: "left", visible: true },
    { id: "action", label: "", minWidth: 10, visible: true },
  ];

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  fontSize: 13,
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                .filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : usersList.length > 0 ? (
              usersList.map((user) => (
                <TableRow
                  key={user.uid}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      border: "none",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {user.firstName.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {user.lastName.toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      sx={{
                        ...styles.table.text,
                        textTransform: "lowercase",
                      }}
                    >
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {user.phoneNumber}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {user.createdAt &&
                        format(
                          new Date(user.createdAt),
                          "dd/MM/yyyy hh:mm:ss aa"
                        )}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography sx={styles.table.text}>
                      {user?.roles[0].name.replace("_", " ").toLowerCase()}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      onClick={() => handleEdit(user.uid)}
                      size="small"
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell>
                    <IconButton
                      aria-label="menu"
                      aria-controls={`menu-${user.id}`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, user.id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id={`menu-${user.id}`}
                      anchorEl={anchorEl}
                      open={selectedRow === user.id}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>View</MenuItem>
                      <MenuItem onClick={handleClose}>Edit</MenuItem>
                      <MenuItem onClick={handleClose}>Delete</MenuItem>
                    </Menu>
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!users.loading && usersList.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: "#62635E" }}>
            Showing {page * rowsPerPage + 1}-
            {Math.min(page * rowsPerPage + rowsPerPage, users.totalRecords)} of{" "}
            {users.totalRecords}
          </Typography>
          <Pagination
            count={Math.ceil(users.totalRecords / rowsPerPage)} // Calculate the number of pages
            variant="outlined"
            page={page + 1} // Adjust the page value to 1-based index
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)} // Adjust the page value to 0-based index
            color="primary"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                borderRadius: 0, // Remove border-radius
                margin: "0", // Remove margins between pagination items
              },
            }}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
