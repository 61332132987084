import { Box, Dialog, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import doneIcon from "../images/done-icon.svg";
import cancelIcon from "../images/cancel-icon.svg";

export const InfoDialog = ({ text, error, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DialogContent sx={{ minWidth: 300 }}>
          <Box
            sx={{
              // minWidth: 400,
              minHeight: 130,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={error ? cancelIcon : doneIcon}
              alt="info-icon"
              width="100px"
            />
          </Box>
          <DialogContentText sx={{ textAlign: "center" }}>
            {text}
          </DialogContentText>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
