import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AuditLogsTable } from "./AuditLogsTable";
import {
  fetchAuditLogs,
  fetchAuditLogsExportData,
  searchAuditLogs,
  searchAuditLogsByDate,
} from "./auditLogsSlice";
import { FilterSection } from "../../common/components/FilterSection";
import { format } from "date-fns";
import { exportToExcel } from "react-json-to-excel";
import { InfoDialog } from "../../common/components/InfoDialog";
import {
  updateDateFilter,
  updateSearchFilter,
} from "../../common/store/filterSlice";

export const AuditLogs = () => {
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const auditLogs = useSelector((state) => state.auditLogs);
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const handleSearch = (searchTerm) => {
    dispatch(updateSearchFilter(searchTerm));
    dispatch(searchAuditLogs({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(
      searchAuditLogsByDate({
        searchTerm: filter.searchTerm,
        page: newPage,
        ...(filter.startDate && {
          startDate: filter.startDate,
        }),
        ...(filter.endDate && {
          endDate: filter.endDate,
        }),
      })
    );
  };

  const onFilterSubmit = (data) => {
    dispatch(updateDateFilter(data));
    dispatch(
      searchAuditLogsByDate({
        searchTerm: filter.searchTerm,
        page: 1,
        ...data,
        startDate: format(data.startDate, "yyyy-MM-dd"),
        endDate: format(data.endDate, "yyyy-MM-dd"),
      })
    );
  };

  const handleExport = (data) => {
    setIsLoading(true);
    dispatch(
      fetchAuditLogsExportData({
        searchTerm: document.getElementById("searchTerm").value,
        startDate: data.startDate
          ? format(data.startDate, "yyyy-MM-dd")
          : filter.startDate,
        endDate: data.endDate
          ? format(data.endDate, "yyyy-MM-dd")
          : filter.endDate,
      })
    ).then((result) => {
      if (fetchAuditLogsExportData.fulfilled.match(result)) {
        const csvData = result.payload.data.auditLogs.reduce((res, item) => {
          res.push({
            staff: `${item.user.firstName} ${item.user.lastName}`,
            phoneNumber: item.user.phoneNumber,
            email: item.user.email,
            log: item.message,
            timestamp: format(
              new Date(item.createdAt),
              "dd/MM/yyyy hh:mm:ss aa"
            ),
          });
          return res;
        }, []);

        exportToExcel(csvData, "AuditLogs");
        setIsLoading(false);
      } else {
        setIsError(true);
        setInfoText("Error occurred while fetching export data");
        setOpen(true);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(fetchAuditLogs(1));
  }, []);

  return (
    <>
      <SectionHeader headerText="Audit Logs" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection
              onSubmit={onFilterSubmit}
              handleExport={handleExport}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Stack>
          <AuditLogsTable
            auditLogs={auditLogs}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Box>
      </WhiteBox>
      <InfoDialog
        open={open}
        error={isError}
        text={infoText}
        handleClose={handleClose}
      />
    </>
  );
};
