import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Link,
  Button,
  InputAdornment,
  IconButton,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import { styles } from "../../common/css/styles";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DevTool } from "@hookform/devtools";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword, sendResetOTP } from "./authSlice";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { passwordRegex } from "../../common/constants/regex";
import { useEffect } from "react";
import { feedback } from "../../common/constants/feedback";
import DisplayError from "../../common/components/DisplayError";

const defaultValues = {
  otp: "",
  password: "",
  confirmPassword: "",
};

const schema = yup.object().shape({
  otp: yup.string().required("OTP is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRegex, "Password does not match required rules"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const ResetPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const onSubmit = (data) => {
    dispatch(
      resetPassword({
        email: location.state.email,
        ...data,
      })
    );
  };

  const backToLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    if (auth.reset === feedback.SUCCESSFUL) navigate("/");
    if (auth.reset === feedback.FAILED) setShowError(true);
  }, [auth.reset]);

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: 2,
          py: 4,
          px: 4,
          justifyContent: "center",
        }}
      >
        <Grid
          container
          item
          position="relative"
          direction="column"
          alignItems="center"
        >
          <Box
            container
            item
            md={6}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            sx={{
              backgroundColor: "primary.main",
              ml: -1,
              pt: 1,
              pb: 1,
              borderRadius: "30px",
              position: "absolute",
              top: -53,
              pl: 2.5,
              pr: 2.5,
            }}
          >
            <Typography sx={{ color: "white", width: "100%" }}>
              Login
            </Typography>
          </Box>
        </Grid>

        <Typography
          variant="h6"
          sx={{ ...styles.formHeader, textAlign: "center" }}
        >
          Change Password
        </Typography>
        <Typography
          sx={{
            ...styles.formHeader,
            fontSize: 13,
            fontWeight: 400,
            letterSpacing: 0,
          }}
        >
          Input your new password
        </Typography>
        <Grid
          container
          direction={"column"}
          spacing={3}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          pt={1}
        >
          <Grid item xs={6}>
            <Typography sx={styles.formFieldHeading}>OTP</Typography>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  size={styles.textfieldSize}
                  required
                  fullWidth
                  error={!!errors.otp}
                  helperText={errors.otp?.message}
                  sx={styles.textField}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography sx={styles.formFieldHeading}>Password</Typography>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  required
                  {...field}
                  size={styles.textfieldSize}
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  sx={styles.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{ color: "black" }}
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={styles.formFieldHeading}>
              Confirm Password
            </Typography>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  required
                  {...field}
                  size={styles.textfieldSize}
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                  sx={styles.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          sx={{ color: "black" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ color: "#9A9FBF", fontSize: 13 }}>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                Eight (8) or more alphabetical characters
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                one (1) or more uppercase character(s)
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                One (1) or more numerical character(s)
              </ListItem>
              <ListItem sx={{ display: "list-item", p: 0 }}>
                One (1) or more special character(s)
              </ListItem>
            </Typography>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Button
              variant="contained"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              sx={styles.continueButton2}
              fullWidth
            >
              Continue
            </Button>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            sx={{ mt: -2 }}
          >
            <Button
              sx={{
                fontWeight: 600,
                fontSize: 14,
                textTransform: "capitalize",
                p: 0,
                pt: 2,
                color: "#000",
              }}
              onClick={backToLogin}
            >
              Back to
              <span style={{ color: "#19AD42", paddingLeft: "5px" }}>
                Login
              </span>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DisplayError
        error={auth.error}
        open={showError}
        handleClose={() => setShowError(false)}
      />
    </>
  );
};
