/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { WalletTransactionsTable } from "./WalletTransactionsTable";
import {
  fetchWalletExportData,
  fetchWalletTransaction,
  fetchWalletTransactions,
  searchWalletTransactions,
  searchWalletTransactionsByDate,
} from "./walletSlice";
import { FilterSection } from "../../common/components/FilterSection";
import { format } from "date-fns";
import { exportToExcel } from "react-json-to-excel";
import {
  updateDateFilter,
  updateSearchFilter,
} from "../../common/store/filterSlice";

export const WalletTransactions = () => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const wallets = useSelector((state) => state.wallets);
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const handleViewClick = (uid) => {
    dispatch(fetchWalletTransaction(uid));
    setOpen(true);
  };

  const handleCloseView = () => setOpen(false);

  const handleSearch = (searchTerm) => {
    dispatch(updateSearchFilter(searchTerm));
    dispatch(searchWalletTransactions({ page: 1, searchTerm }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
    dispatch(
      searchWalletTransactionsByDate({
        searchTerm: filter.searchTerm,
        page: newPage,
        ...(filter.startDate && {
          startDate: filter.startDate,
        }),
        ...(filter.endDate && {
          endDate: filter.endDate,
        }),
      })
    );
  };

  const handleExport = (data) => {
    setIsLoading(true);
    dispatch(
      fetchWalletExportData({
        searchTerm: document.getElementById("searchTerm").value,
        startDate: data.startDate
          ? format(data.startDate, "yyyy-MM-dd")
          : filter.startDate,
        endDate: data.endDate
          ? format(data.endDate, "yyyy-MM-dd")
          : filter.endDate,
      })
    ).then((result) => {
      if (fetchWalletExportData.fulfilled.match(result)) {
        const csvData = result.payload.data.transactions.reduce((res, item) => {
          res.push({
            transactionID: item.uid,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            phoneNumber: item.user.phoneNumber,
            narration: item.narration,
            amount: item.amount,
            timestamp: format(
              new Date(item.updatedAt),
              "dd/MM/yyyy hh:mm:ss aa"
            ),
          });
          return res;
        }, []);

        exportToExcel(csvData, "WalletsTransactions");
        setIsLoading(false);
      }
    });
  };

  const onFilterSubmit = (data) => {
    dispatch(updateDateFilter(data));
    dispatch(
      searchWalletTransactionsByDate({
        searchTerm: filter.searchTerm,
        page: 1,
        ...data,
        startDate: format(data.startDate, "yyyy-MM-dd"),
        endDate: format(data.endDate, "yyyy-MM-dd"),
      })
    );
  };

  useEffect(() => {
    dispatch(fetchWalletTransactions(1));
  }, []);

  return (
    <>
      <SectionHeader headerText="Wallet Transactions" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />
            <FilterSection
              onSubmit={onFilterSubmit}
              handleExport={handleExport}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Stack>
          <WalletTransactionsTable
            wallets={wallets}
            page={page}
            handlePageChange={handlePageChange}
            open={open}
            handleViewClick={handleViewClick}
            handleCloseView={handleCloseView}
          />
        </Box>
      </WhiteBox>
    </>
  );
};
