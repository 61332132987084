import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Paper,
  TableContainer,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DeadState } from "../../../common/components/DeadState";
import { styles } from "../../../common/css/styles";
import { PageNavigation } from "../../../common/components/PageNavigation";
import { CollectionTransactionDetails } from "../CollectionTransactionDetails";

export const CollectionTable = ({
  handlePageChange,
  open,
  loading,
  collections = [],
  orderBy,
  handleSort,
  sortedData,
  columns,
  query,
  handleClose,
  total,
  order,
}) => {
  return (
    <Paper
      sx={{
        // maxHeight: 400,
        boxShadow: "none",
        pt: 2,
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columns
                ?.filter((column) => column)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      <Typography
                        noWrap
                        sx={{
                          color: "#000",
                          backgroundColor: "#fff",
                          fontSize: 13,
                          fontWeight: 600,
                        }}
                      >
                        {column.label}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : sortedData?.length > 0 ? (
              sortedData?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  {columns.map((v, i) => (
                    <TableCell key={i} align="left">
                      <Typography sx={styles.table.text.uid}>
                        {v.render ? v.render(loan) : loan[v.id]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={columns?.length} sx={{ textAlign: "center" }}>
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && total > 0 ? (
        <PageNavigation
          page={query.page - 1}
          totalRecords={total}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}

      {open && (
        <CollectionTransactionDetails
          open={open}
          handleClose={handleClose}
          transaction={open}
        />
      )}
    </Paper>
  );
};
