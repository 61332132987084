import React, { useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { SearchBar } from "../../common/components/SearchBar";
import { Box, Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { CollectionTable } from "./components/CollectionsTable";
import { FilterSection2 } from "../../common/components/FilterSection2";
import {
  formatCurrencyvalue,
  getTranactionStatusDisplay,
} from "../../common/util";
import { overviewFilters } from "../overview/overviewFilters";
import { useQuery } from "@tanstack/react-query";
import {
  getAllCollections,
  getAllCollectionsForExport,
} from "../../common/api/collections";
import { useLoanParams } from "../overview/hooks/useLoanParams";
import { useLocation, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { styles } from "../../common/css/styles";
import { exportToExcel } from "react-json-to-excel";
import { handleErrorAlt } from "../../common/constants/utility";
import { ProofOfPayment } from "./ProofOfPayment";

export const Collection = () => {
  const { getDateRange } = useLoanParams();
  const search = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams(search);
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [open, setOpen] = useState(null);
  const [openPop, setOpenPop] = useState(null);

  const handleOpen = (v) => {
    const { narration, proofOfPaymentUrl, transactionUid } = v ?? {};
    if (narration === "Principal collection") setOpenPop(proofOfPaymentUrl);
    else setOpen(transactionUid);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenPop(false);
  };
  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(columnId);
      setOrder("asc");
    }
  };

  const [query, setQuery] = useState({
    ...getDateRange(overviewFilters.THREE_MONTHS),
    page: 1,
    size: 10,

    ...searchParams,
  });

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["collections", query],
    queryFn: () => getAllCollections(query),
    refetchOnWindowFocus: false,
  });

  const handlePageChange = (event, newPage) => {
    const queryData = { ...query, page: newPage };

    setQuery(queryData);
    setSearchParams(queryData);
  };

  const handleSearch = (search) => {
    const queryData = { ...query, search, page: 1 };

    setQuery(queryData);
    setSearchParams(queryData);
  };

  const handleDateFilter = (data) => {
    const { startDate, endDate } = data ?? {};

    const queryData = {
      ...query,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    };

    setQuery(queryData);
    setSearchParams(queryData);
  };

  const columns = [
    { id: "uid", label: "Collection ID" },
    {
      id: "senderAccountName",
      label: "Sender Account Name",

      // render: (v) => (
      //   <span style={{ textTransform: "capitalize" }}>
      //     {`${v.userBasicInfo?.firstName ?? ""} ${
      //       v.userBasicInfo?.lastName ?? ""
      //     }`}
      //   </span>
      // ),
    },
    // {
    //   id: "lastName",
    //   label: "Last Name",

    //   render: (v) => (
    //     <span style={{ textTransform: "capitalize" }}>
    //       {v.userBasicInfo?.lastName}
    //     </span>
    //   ),
    // },
    { id: "phoneNumber", label: "Phone Number" },
    { id: "narration", label: "Narration" },

    {
      id: "collectedAmount",
      label: "Amount",
      render: (v) => (
        <span style={{ color: "green" }}>
          {formatCurrencyvalue(v.collectedAmount)}
        </span>
      ),
    },
    {
      id: "status",
      label: "Status",
      render: (v) => getTranactionStatusDisplay(v.status),
    },

    {
      id: "timeStamp",
      label: "Timestamp",

      render: (loan) => (
        <span
          style={{
            ...styles.table.text,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {format(new Date(loan?.collectionDate), "dd/MM/yyyy hh:mm:ss aa")}
        </span>
      ),
    },
    {
      id: "action",
      label: "Action",

      render: (v) => (
        <Button
          variant="outlined"
          onClick={() => handleOpen(v)}
          sx={styles.table.text.viewButton}
        >
          View
        </Button>
      ),
    },
  ];

  const { total, collections = [] } = data?.data?.data ?? {};

  const sortedData = collections.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const loading = isLoading || isRefetching;

  const [csvLoading, setCsvLoading] = useState(false);
  const exportDataToCsv = async () => {
    try {
      setCsvLoading(true);
      const { data } = await getAllCollectionsForExport(query);
      setCsvLoading(false);
      if (data) {
        const csvDataToExport = data.data?.collections?.map((item) => ({
          "Transaction ID": item.uid,
          "Collected Amount": item.collectedAmount,
          Status: item.status,
          "Collection Date": item.collectionDate,
          "Date Initiated": item.dateInitiated,
          Provider: item.provider,
          Narration: item.narration,
          "First Name": item.userBasicInfo?.firstName,
          "Last Name": item.userBasicInfo?.lastName,
          "Loan Uid": item.loanUid,
          "Collection Cycle": item.collectionCycle,
          "Loan Type": item.loanType,
        }));

        exportToExcel(csvDataToExport, "Collection");
      } else handleErrorAlt("", dispatch);
    } catch (error) {
      setCsvLoading(false);
      handleErrorAlt(error, dispatch);
    }
  };

  return (
    <>
      <SectionHeader headerText="Collections" />
      <WhiteBox>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SearchBar onSearch={handleSearch} />

            <FilterSection2
              exportDataToCsv={exportDataToCsv}
              csvLoading={csvLoading}
              handleDateFilter={handleDateFilter}
            />
          </Stack>
          <CollectionTable
            handlePageChange={handlePageChange}
            open={open}
            loading={loading}
            collections={collections}
            orderBy={orderBy}
            handleSort={handleSort}
            sortedData={sortedData}
            columns={columns}
            query={query}
            handleClose={handleClose}
            total={total}
            order={order}
          />
        </Box>
      </WhiteBox>
      {openPop && (
        <ProofOfPayment
          open={openPop}
          handleClose={handleClose}
          url={openPop}
        />
      )}
    </>
  );
};
