import { client } from ".";
import { unAuthClient } from ".";

export const sendOTP = ({ recipient, operation }) => {
  return client.post("auth/send-otp", { recipient, operation });
};

export const sendOTPAdmin = ({ recipient, operation, channel }) => {
  return client.post("auth/admin/send-otp", { recipient, operation, channel });
};

export const verifyOTP = ({ recipient, operation, otp }) => {
  return unAuthClient.post("auth/verify-otp", { recipient, operation, otp });
};
