import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { styles } from "../../common/css/styles";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { formatCurrencyvalue } from "../../common/util";
import { getCollectionTransactionInfo } from "../../common/api/collections";
import { useQuery } from "@tanstack/react-query";

export const CollectionTransactionDetails = ({ open, handleClose, id }) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["collectionTransaction", open],
    queryFn: () => getCollectionTransactionInfo(open),
    refetchOnWindowFocus: false,
  });

  const transaction = data?.data?.data ?? {};

  const loadingTransaction = isLoading || isRefetching;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ minWidth: 550 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 0,
            mx: 2.5,
            mt: 2,
          }}
        >
          <DialogTitle sx={{ color: "#000", p: 0 }}>
            Transaction Details
          </DialogTitle>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <Close sx={{ color: "#000", p: 0 }} />
          </IconButton>
        </Box>

        <DialogContent>
          {loadingTransaction ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container direction="column" spacing={2}>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Amount
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {formatCurrencyvalue(transaction?.amount)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Beneficiary Account
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.recipientAccountNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Account Name
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.recipientAccountName}
                  </Typography>
                </Box>
              </Grid>

              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Bank
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.recipientBankName?.toUpperCase()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Transaction Type
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.transactionType?.toLowerCase()}
                  </Typography>
                </Box>
              </Grid>

              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Transaction Status
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.status}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Narration
                  </Typography>
                  <Typography
                    sx={{
                      ...styles.transactionModal.rightText,
                      textAlign: "right",
                      maxWidth: 400,
                    }}
                  >
                    {transaction?.narration}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={styles.transactionModal.box}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Transaction Reference
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.reference}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sx={styles.transactionModal.grid}>
                <Box sx={{ ...styles.transactionModal.box, border: "none" }}>
                  <Typography sx={styles.transactionModal.leftText}>
                    Transaction Date
                  </Typography>
                  <Typography sx={styles.transactionModal.rightText}>
                    {transaction?.updatedAt &&
                      format(
                        new Date(transaction?.updatedAt),
                        "dd/MM/yyyy hh:mm:ss aa"
                      )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};
