import React, { useEffect, useState } from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { Box, Button, Stack } from "@mui/material";
import { ChangeDeviceTable } from "./components/ChangeDeviceTable";
import { SearchBar } from "../../common/components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../common/css/styles";
import { AddDeviceTable } from "./components/AddDeviceTable";
import { deviceFilters } from "./constants/deviceFilters";
import {
  fetchCustomersForDeviceAdd,
  fetchCustomersForDeviceChange,
  searchCustomersForDeviceAdd,
  searchCustomersForDeviceChange,
} from "./devicesSlice";

export const ChangeDevice = () => {
  // const [showList, setShowList] = useState(true);
  // const [option, setOption] = useState("");
  const [page, setPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState(deviceFilters.ADD_DEVICE);
  const devices = useSelector((state) => state.devices);
  const dispatch = useDispatch();
  const handleFilter = (filter) => setActiveFilter(filter);

  const filters = [
    {
      label: "Add Mobile Device",
      value: deviceFilters.ADD_DEVICE,
      visible: true,
    },
    {
      label: "Change Mobile Device",
      value: deviceFilters.CHANGE_DEVICE,
      visible: true,
    },
  ];

  const getCustomers = (page) => {
    if (activeFilter === deviceFilters.ADD_DEVICE)
      dispatch(fetchCustomersForDeviceAdd(page));
    else dispatch(fetchCustomersForDeviceChange(page));
  };

  const handleSearch = (searchTerm) => {
    if (activeFilter === deviceFilters.ADD_DEVICE)
      dispatch(searchCustomersForDeviceAdd({ page: 1, searchTerm }));
    else dispatch(searchCustomersForDeviceChange({ page: 1, searchTerm }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    getCustomers(newPage);
  };

  // const handleAdd = () => {
  //   setShowList(false);
  //   setOption("Add");
  // };

  // const handleComplete = () => {
  //   setShowList(true);
  //   setOption("");
  // };

  // const handleEdit = (userUid) => {
  //   // dispatch(fetchUser(userUid));
  //   setShowList(false);
  //   setOption("Edit");
  // };

  useEffect(() => {
    getCustomers(1);
  }, [activeFilter]);

  return (
    <>
      <SectionHeader headerText="Customer Device Management" />
      <WhiteBox>
        {/* {showList ? ( */}
        <Box p={2}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SearchBar onSearch={handleSearch} />
          </Stack>
          <Box sx={{ py: 2 }}>
            {filters
              .filter((filter) => filter.visible === true)
              .map((filter) => (
                <Button
                  key={filter.value}
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? styles.tableFilter.active
                      : styles.tableFilter.inactive
                  }
                  // sx={styles.tableFilter.active}
                >
                  {filter.label}
                </Button>
              ))}
          </Box>
          {activeFilter === deviceFilters.ADD_DEVICE && (
            <AddDeviceTable
              devices={devices}
              page={page}
              handlePageChange={handleChangePage}
            />
          )}
          {activeFilter === deviceFilters.CHANGE_DEVICE && (
            <ChangeDeviceTable
              devices={devices}
              page={page}
              handlePageChange={handleChangePage}
            />
          )}
        </Box>
        {/* ) : option === "Add" ? (
          <AddUser handleComplete={handleComplete} />
        ) : (
          <EditUser handleComplete={handleComplete} />
        )} */}
      </WhiteBox>
    </>
  );
};
