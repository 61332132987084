import React from "react";
import { SectionHeader } from "../../common/components/SectionHeader";
import { WhiteBox } from "../../common/components/WhiteBox";
import { Box } from "@mui/material";
import { InterestRate } from "./InterestRate";

export const Settings = () => {
  return (
    <>
      <SectionHeader headerText="Interest Rates" />
      <WhiteBox>
        <Box sx={{ p: 2 }}>
          <InterestRate />
        </Box>
      </WhiteBox>
    </>
  );
};
