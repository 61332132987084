import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
import React, { useState } from "react";
import { DeadState } from "../../../common/components/DeadState";
import { styles } from "../../../common/css/styles";
import { CreditDetails } from "../../loans/components/CreditDetails";
import { retailFilters } from "../../loans/constants/retailFilters";

const columns = [
  {
    id: "bank",
    label: "Bank",
    minWidth: 50,
    align: "left",
  },
  {
    id: "tenure",
    label: "Tenure",
    minWidth: 50,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 50,
    align: "left",
  },
  {
    id: "interest",
    label: "Interest",
    minWidth: 50,
    align: "left",
  },
  {
    id: "loanStatus",
    label: "Loan Status",
    minWidth: 50,
    align: "left",
  },
  {
    id: "view",
    label: "View",
    minWidth: 50,
    align: "left",
  },
];

const tempLoanList = [
  {
    bank: "Opay",
    lastName: "Luthor",
    amount: "N2,500,000",
    tenure: "3Months",
    interest: "N100,000",
    status: "PENDING_RECOMMENDATION",
  },
];

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 32,
  fontWeight: 700,
}));

const StyledText2 = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 14,
  fontWeight: 400,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
      <StyledText2 x={left + width / 2} y={(top + height) / 2 + 25}>
        Doing great
      </StyledText2>
    </>
  );
}

function PieLeftLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText2 x={left + width / 2 - 78} y={(top + height) / 2 + 78}>
        {children}
      </StyledText2>
    </>
  );
}

function PieRightLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <>
      <StyledText2 x={left + width / 2 + 78} y={(top + height) / 2 + 78}>
        {children}
      </StyledText2>
    </>
  );
}

const pieParams = { height: 200, margin: { right: 5 } };
const palette = ["red", "blue", "green"];

export const CreditBureau = ({ customer, data }) => {
  const [activeFilter, setActiveFilter] = useState("CRC");
  const [open, setOpen] = useState(false);

  const handleView = (uid) => {
    // dispatch(fetchWalletTransaction(uid));
    setOpen(true);
  };

  const handleCloseView = () => setOpen(false);

  // const handleView = (uid) => navigate(`/loans/details/${uid}`);

  const filters = [
    { label: "FRC", value: "FRC", visible: true },
    { label: "CRC", value: "CRC", visible: true },
  ];

  const handleFilter = (filter) => {
    setActiveFilter(filter);
  };

  const getStatusDisplay = (status) => {
    switch (status) {
      case retailFilters.COMPLETED:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Completed
          </Typography>
        );
      case retailFilters.NTUS:
        return (
          <Typography sx={styles.table.text.customerStatus.prospect}>
            Application
          </Typography>
        );
      default:
        return (
          <Typography sx={styles.table.text.customerStatus.pending}>
            Completed
          </Typography>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // mt: 4,
          }}
        >
          <Box sx={{ py: 1, display: "flex" }}>
            {filters
              .filter((filter) => filter.visible)
              .map((filter) => (
                <Button
                  onClick={() => handleFilter(filter.value)}
                  variant="outlined"
                  sx={
                    activeFilter === filter.value
                      ? { ...styles.tableFilter.active, fontSize: 14, p: 2.5 }
                      : { ...styles.tableFilter.inactive, fontSize: 14, p: 2.5 }
                  }
                >
                  {filter.label}
                </Button>
              ))}
          </Box>

          <Box sx={{ py: 1, display: "flex" }}>
            <Button
              variant="outlined"
              sx={{
                ...styles.tableFilter.active,
                fontSize: 14,
                p: 2.5,
                borderRadius: 1,
              }}
            >
              Update Report
            </Button>
          </Box>
        </Box>

        <Box sx={{ border: 2, p: 1, borderRadius: 2, borderColor: "#F0F1ED" }}>
          <Box sx={{ py: 1, display: "flex" }}>
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: "#000",
                ml: 1.2,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Last updated: Nov 28, 2023
            </Typography>
          </Box>

          <Box
            sx={{
              // display: "flex",
              // justifyContent: "center",
              // width: "100%",
              mt: 3,
            }}
          >
            <Box>
              <PieChart
                series={[
                  {
                    data: [
                      { value: 10, color: "red" },
                      { value: 20, color: "orange" },
                      { value: 15, color: "green" },
                      { value: 10, color: "grey" },
                    ],
                    innerRadius: 87,
                    outerRadius: 103,
                    paddingAngle: 1,
                    cornerRadius: 5,
                    startAngle: -125,
                    endAngle: 126,
                    cx: "50%",
                    cy: "55%",
                  },
                ]}
                {...pieParams}
              >
                <PieCenterLabel>87</PieCenterLabel>
                <PieLeftLabel>300</PieLeftLabel>
                <PieRightLabel>850</PieRightLabel>
              </PieChart>
            </Box>
          </Box>

          <Box
            item
            sx={{
              width: "100%",

              borderRadius: 2,
              my: 2,
            }}
          >
            <Box sx={{ px: 2.5 }}>
              <Stack>
                <Box
                  sx={{
                    display: "flex",
                    wrap: "wrap",
                    justifyContent: "space-between",
                    mb: 2,
                    // width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      ...styles.overview.statistics.mediumBox,
                      borderLeft: 5,
                      borderLeftColor: "#3F76C3B2",
                      backgroundColor: "#F2F4F7",
                      width: "50%",
                    }}
                  >
                    <Stack sx={{ px: 1 }}>
                      <Typography sx={styles.overview.statistics.number}>
                        6
                      </Typography>
                      <Typography sx={styles.overview.statistics.info}>
                        Total Performing Account
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      ...styles.overview.statistics.mediumBox,
                      borderLeft: 5,
                      borderLeftColor: "#F3A02B",
                      backgroundColor: "#F7F5F2",
                      width: "50%",
                    }}
                  >
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.number}>
                        100%
                      </Typography>
                      <Typography sx={styles.overview.statistics.info}>
                        Payment History
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    wrap: "wrap",
                    justifyContent: "space-between",
                    // width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      ...styles.overview.statistics.mediumBox,
                      borderLeft: 5,
                      borderLeftColor: "#F43434",
                      backgroundColor: "#F7F2F2",
                      width: "50%",
                    }}
                  >
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.number}>
                        0%
                      </Typography>
                      <Typography sx={styles.overview.statistics.info}>
                        Paid Off loans
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      ...styles.overview.statistics.mediumBox,
                      borderLeft: 5,
                      borderLeftColor: "#C130CE",
                      backgroundColor: "#F7F2F7",
                      width: "50%",
                    }}
                  >
                    <Stack sx={{ pl: 2 }}>
                      <Typography sx={styles.overview.statistics.number}>
                        0
                      </Typography>
                      <Typography sx={styles.overview.statistics.info}>
                        Missed Payment
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          p: 2,
          pt: 4,
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
          <Typography sx={{ px: 2, pl: 3.5, fontWeight: 600, fontSize: 20 }}>
            Credit History
          </Typography>
        </Box>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            border: "none",
            boxShadow: "none",
          }}
        >
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ backgroundColor: "transparent" }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-head": {
                      color: "#000",
                      backgroundColor: "transparent",
                      border: "4px solid black",
                    },
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        borderTop: "1px solid #B5B5B5",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid #B5B5B5",
                        fontSize: 13,
                        fontWeight: 600,
                        height: 20,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tempLoanList ? (
                  tempLoanList?.map((loan) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={loan.uid}
                      sx={{
                        "& .MuiTableCell-body": {
                          color: "#62635E",
                          backgroundColor: "#fff",
                          border: "none",
                          fontSize: 13,
                          fontWeight: 400,
                        },
                      }}
                    >
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.bank}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.tenure}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.amount}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {loan.interest}
                      </TableCell>
                      <TableCell align="left">
                        {getStatusDisplay(loan.status)}
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          variant="outlined"
                          onClick={() => handleView(loan.uid)}
                          sx={styles.table.text.viewButton}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell
                    colSpan={columns.length}
                    sx={{ textAlign: "center" }}
                  >
                    <DeadState />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <CreditDetails open={open} handleClose={handleCloseView} />
    </>
  );
};
