import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";

export const ProofOfPayment = ({ url, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ minWidth: 550 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 0,
            mx: 2.5,
            mt: 2,
          }}
        >
          <DialogTitle sx={{ color: "#000", p: 0 }}>
            Proof Of Payment
          </DialogTitle>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <Close sx={{ color: "#000", p: 0 }} />
          </IconButton>
        </Box>

        <DialogContent>
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "70vh" }}
          >
            <img
              src={url}
              alt="proof of payment"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
