import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTransaction,
  getTransactions,
  getWalletExportData,
  searchTransactions,
  searchTransactionsByDate,
} from "../../common/api/wallets";
import { handleError } from "../../common/constants/utility";

const initialState = {
  transactions: [],
  transaction: null,
  loading: false,
  loadingTransaction: false,
  totalRecords: 0,
  error: [],
};

export const fetchWalletExportData = createAsyncThunk(
  "wallets/fetch/export",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await getWalletExportData(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchWalletTransactions = createAsyncThunk(
  "wallets/fetch/all",
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await getTransactions(page);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const fetchWalletTransaction = createAsyncThunk(
  "wallets/fetch",
  async (walletId, { dispatch, rejectWithValue }) => {
    try {
      const response = await getTransaction(walletId);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchWalletTransactions = createAsyncThunk(
  "wallets/search",
  async ({ page, searchTerm }, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchTransactions(page, searchTerm);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

export const searchWalletTransactionsByDate = createAsyncThunk(
  "wallets/searchByDate",
  async (model, { dispatch, rejectWithValue }) => {
    try {
      const response = await searchTransactionsByDate(model);
      return await response.data;
    } catch (err) {
      return handleError(err, dispatch, rejectWithValue);
    }
  }
);

const walletSlice = createSlice({
  name: "wallets",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchWalletTransactions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchWalletTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.data.transactions;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(fetchWalletTransactions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(searchWalletTransactions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchWalletTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.data.transactions;
      state.totalRecords = action.payload.data.totalRecords;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(searchWalletTransactions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchWalletTransaction.pending, (state, action) => {
      state.loadingTransaction = true;
    });
    builder.addCase(fetchWalletTransaction.fulfilled, (state, action) => {
      state.transaction = action.payload.data;
      state.loadingTransaction = false;
      state.error = [];
    });
    builder.addCase(fetchWalletTransaction.rejected, (state, action) => {
      state.loadingTransaction = false;
      state.error = action.payload;
    });
    builder.addCase(searchWalletTransactionsByDate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      searchWalletTransactionsByDate.fulfilled,
      (state, action) => {
        state.transactions = action.payload.data.transactions;
        state.totalRecords = action.payload.data.totalRecords;
        state.loading = false;
        state.error = [];
      }
    );
    builder.addCase(
      searchWalletTransactionsByDate.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default walletSlice.reducer;
